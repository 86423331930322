<template>
  <div class="content">
    <el-container>
      <el-main style="background: #ffffff">
        <head-layout
          :head-title="headTiTle"
          :head-btn-options="
            type == 'view' ? headBtnViewsOptions : headBtnOptions
          "
          @head-save="headSave"
          @head-add="headAdd"
          @head-cancel="headCancel"
        ></head-layout>
        <div style="padding: 12px">
          <el-form
            :model="form"
            label-width="120px"
            ref="form"
            :rules="rules"
            :disabled="type == 'view'"
          >
            <el-row>
              <el-col :span="8">
                <el-form-item label="处置申请编号" prop="disposalCode">
                  <el-input v-model="form.disposalCode" maxlength="64">
                    <span slot="append">
                      自动生成
                      <el-switch
                        v-model="codeAuto"
                        @change="codeAutoClick"
                      ></el-switch>
                    </span>
                  </el-input>
                </el-form-item>
              </el-col>
              <!-- <el-col :span="3">
                <el-form-item label="自动生成">
                  <el-switch
                    v-model="codeAuto"
                    @change="codeAutoClick"
                  ></el-switch>
                </el-form-item>
              </el-col> -->
              <el-col :span="8">
                <el-form-item label="危化品名称" prop="itemName">
                  <el-input
                    v-model="form.itemName"
                    @focus="clickListDialog"
                    :maxlength="255"
                    show-word-limit
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="处置数量" prop="quantity">
                  <el-input-number
                    v-model="form.quantity"
                    :min="1"
                    :precision="2"
                    @input="handleInput"
                    placeholder=""
                  ></el-input-number>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="批次号" prop="batchCode">
                  <el-input v-model="form.batchCode" disabled></el-input>
                </el-form-item>
              </el-col>
              <!-- <el-col :span="3">
                <el-form-item label=""></el-form-item>
              </el-col> -->
              <el-col :span="8">
                <el-form-item label="处置时间" prop="disposalDate">
                  <el-date-picker
                    type="date"
                    placeholder="选择日期"
                    v-model="form.disposalDate"
                    :default-value="new Date()"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    style="width: 100%"
                  ></el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="申请人" prop="userName">
                  <el-input
                    v-model="form.userName"
                    @focus="handleUserModeOpen()"
                    placeholder="申请人"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="处置原因" prop="remark">
                  <el-input
                    type="textarea"
                    v-model="form.remark"
                    :maxlength="500"
                    show-word-limit
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
      </el-main>
    </el-container>
    <el-container>
      <el-main style="background: #ffffff">
        <head-layout
          head-title="供应商信息"
          :head-btn-options="[]"
          @head-save="headSave"
          @head-add="headAdd"
          @head-cancel="headCancel"
        ></head-layout>
        <div style="padding: 12px">
          <el-form :model="form" label-width="100px">
            <el-row>
              <el-col :span="12">
                <el-form-item label="供应商名称">
                  <!-- <el-input v-model="form.dmItemVendor.vendorName"></el-input> -->
                  <div>{{ form.dmItemVendor.vendorName }}</div>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="生产许可证号">
                  <div>{{ form.dmItemVendor.license }}</div>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="技术说明书">
                  <el-link @click="showFile(form.dmItemVendor.docUrl)">{{
                    form.dmItemVendor.docName
                  }}</el-link>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="产品合格证">
                  <el-link @click="showFile(form.dmItemVendor.qcUrl)">{{
                    form.dmItemVendor.qcName
                  }}</el-link>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
      </el-main>
    </el-container>
    <el-container>
      <el-main style="background: #ffffff">
        <head-layout
          head-title="处置单位信息"
          :head-btn-options="[]"
          @head-save="headSave"
          @head-add="headAdd"
          @head-cancel="headCancel"
        ></head-layout>
        <div style="padding: 12px">
          <el-form
            :model="form"
            label-width="100px"
            :rules="rules"
            ref="czForm"
            :disabled="type == 'view'"
          >
            <el-row>
              <el-col :span="12">
                <el-form-item label="处置单位" prop="vendorName">
                  <el-input
                    v-model="form.vendorName"
                    :maxlength="255"
                    show-word-limit
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="资质证书">
                  <el-input
                    v-model="form.certificate"
                    :maxlength="255"
                    show-word-limit
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="备注说明">
                  <el-input
                    type="textarea"
                    v-model="form.disposalRemark"
                    :maxlength="500"
                    show-word-limit
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
      </el-main>
    </el-container>
    <!-- 选择危险品名称 -->
    <el-dialog
      title="请选择危险品"
      v-dialogDrag
      :visible.sync="listDialog"
      class="listDialog"
      width="80%"
    >
      <div style="width: 100%; height: 520px">
        <grid-layout
          ref="gridLayOut"
          :table-options="tableOption"
          :data-total="page.total"
          :table-data="tableData"
          :table-loading="tableLoading"
          @gird-handle-select-click="selectionChange"
          :page="page"
          @page-current-change="getList"
          @page-size-change="getList"
        >
          <template #customBtn="{ row }">
            <el-button
              style="margin: 0 3px"
              type="text"
              size="small"
              @click="selectionRowChange(row)"
              >选择</el-button
            >
          </template>
        </grid-layout>
      </div>
      <div class="avue-dialog__footer">
        <el-button @click="listDialog = false">取 消</el-button>
        <el-button @click="handleselectionChange" type="primary"
          >确 定</el-button
        >
      </div>
    </el-dialog>
    <fileView ref="fileView" title="危化品相关文件预览"></fileView>
    <el-dialog
      title="请选择处置人"
      :visible.sync="personShow"
      width="80%"
      top="8vh"
    >
      <UserDetpDialog
        ref="UserDetpDialog"
        @select-data="selectData"
      ></UserDetpDialog>
    </el-dialog>
  </div>
</template>

<script>
import {
  getkcList,
  getczCode,
  addczList,
  listczDetail,
} from "@/api/hazardousChemicals/index.js";
import website from "@/config/website";
import { mapGetters } from "vuex";
import GridLayout from "@/views/components/layout/grid-layout";
import HeadLayout from "@/views/components/layout/head-layout";
import { getToken } from "@/util/auth";
import UserDetpDialog from "@/views/components/UserDeptDialog/UserDetpDialog";
export default {
  components: {
    UserDetpDialog,
    GridLayout,
    HeadLayout,
  },
  data() {
    return {
      type: "add",
      personShow: false,
      headTiTle: "处置申请",
      listDialog: false,
      tableData: [],
      tableLoading: false,
      codeAuto: true,
      batchCodeAuto: true,
      form: {
        dmItemVendor: {},
        disposalDate: new Date(),
      },
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0,
      },
      option: [],
      headBtnOptions: [
        {
          label: "保存",
          emit: "head-save",
          type: "button",
          btnOptType: "save",
        },
        {
          label: "提交",
          emit: "head-add",
          type: "button",
          btnOptType: "submit",
        },
        {
          label: "取消",
          emit: "head-cancel",
          type: "button",
          btnOptType: "cancel",
        },
      ],
      headBtnViewsOptions: [
        {
          label: "取消",
          emit: "head-cancel",
          type: "button",
          btnOptType: "cancel",
        },
      ],
      tableOption: {
        menuWidth: 100,
        menuFixed: "right",
        column: [
          {
            label: "危化品名称",
            prop: "itemName",
            width: 200,
            align: "center",
            overHidden: true,
          },
          {
            label: "规格",
            prop: "spec",
            width: 200,
            align: "center",
            overHidden: true,
          },
          {
            label: "批次号",
            prop: "batchCode",
            align: "center",
            overHidden: true,
          },
          {
            label: "有效期",
            prop: "expireDate",
            align: "center",
            overHidden: true,
          },

          {
            label: "储位",
            prop: "warehouseName",
            align: "center",
            overHidden: true,
          },
          {
            label: "库存量",
            prop: "quantity",
            align: "center",
            overHidden: true,
          },
        ],
      },
      rules: {
        disposalCode: [
          { required: true, message: "请输入处置编号", trigger: "blur" },
        ],
        itemName: [
          { required: true, message: "请选择危化品名称", trigger: "change" },
        ],
        batchCode: [
          { required: true, message: "请输入批次号", trigger: "blur" },
        ],
        quantity: [
          { required: true, message: "请输入处置数量", trigger: "blur" },
        ],
        disposalDate: [
          { required: true, message: "请选择处置时间", trigger: "change" },
        ],
        userName: [
          { required: true, message: "请选择申请人", trigger: "change" },
        ],
        remark: [
          { required: true, message: "请填写处置原因", trigger: "change" },
        ],
        vendorName: [
          {
            required: true,
            message: "请填写处置单位",
            trigger: "blur",
          },
        ],
      },
      dmItemInfo: {},
      itemList: [],
      itemId: "",
    };
  },
  computed: {
    ...mapGetters(["userInfo"]),
    headers: function () {
      return { "Sinoma-Auth": getToken() };
    },
  },
  created() {
    this.type = this.$route.query.type || "add";
    this.id = this.$route.query.id || "";
    this.itemId = this.$route.query.itemId || "";
    if (this.type == "add") {
      this.clickListDialog();
      this.getczCode();
      this.form.disposalDate = this.formatDate(new Date());
    } else {
      this.getDetail(this.id);
    }

    this.getList(this.page);
  },
  methods: {
    handleInput(value) {
      this.form.quantity = Number(value.toFixed(2));
      if (this.form.quantity == 0) {
        this.form.quantity = 1;
        this.$message({
          message: "处置量必须大于0",
          type: "warning",
        });
      }
    },
    formatDate(date) {
      function padZero(num) {
        return num < 10 ? "0" + num : num;
      }

      var year = date.getFullYear();
      var month = padZero(date.getMonth() + 1);
      var day = padZero(date.getDate());
      var hours = padZero(date.getHours());
      var minutes = padZero(date.getMinutes());
      var seconds = padZero(date.getSeconds());

      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    },
    selectionChange(arr) {
      console.log(arr);
      this.itemList = arr;
    },
    handleselectionChange() {
      console.log(this.itemList);
      if (this.itemList.length > 1) {
        this.$message({
          message: "只能选择一条数据",
          type: "warn",
        });
        return;
      }
      if (this.itemList.length == 1) {
        let items = this.itemList[0];
        // this.form.itemName = items.itemName;
        this.$set(this.form, "itemName", items.itemName);
        this.form.itemId = items.itemId;
        this.form.itemCode = items.itemCode;
        // this.form.batchCode = items.batchCode;
        this.$set(this.form, "batchCode", items.batchCode);
        this.form.warehouseId = items.warehouseId;
        this.form.spec = items.spec;
        this.form.unitOfMeasure = items.unitOfMeasure;
        this.form.dmItemVendor = items.dmItemVendor;
      }
      this.listDialog = false;
    },
    clickListDialog() {
      this.listDialog = true;
    },
    handleUserModeOpen() {
      this.personShow = true;
    },
    selectData(row) {
      this.personShow = false;
      this.form.userId = row.id;
      // this.form.userName = row.realName || row.nickName;
      this.$set(this.form, "userName", row.realName || row.nickName);
    },
    codeAutoClick() {
      if (this.codeAuto) {
        this.getcgCode();
      } else {
        this.form.recptCode = "";
      }
    },
    getDetail(id) {
      listczDetail({ id }).then((res) => {
        this.form = res.data.data;
        console.log(this.form);
      });
    },
    // 获取危险品种类列表
    getList(page) {
      this.page = page;
      this.tableLoading = true;
      let parmas = {
        organizationId: this.userInfo.dept_id,
        size: this.page.pageSize,
        current: this.page.currentPage,
      };
      if (this.itemId) {
        parmas.itemId = this.itemId;
      }
      getkcList(parmas)
        .then((res) => {
          this.tableData = res.data.data.records;
          this.page.total = res.data.data.total;
          this.tableLoading = false;
        })
        .catch((error) => {
          this.tableLoading = false;
        });
    },
    // 取消
    headCancel() {
      this.$router.$avueRouter.closeTag();
      this.$router.back();
    },
    headSave() {
      this.addList("PREPARE");
    },
    headAdd() {
      this.addList("FINISHED");
    },
    addList(recptStatus) {
      let parmas = {
        organizationId: this.userInfo.dept_id,
        ...this.form,
        recptStatus,
      };
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$refs.czForm.validate((valid) => {
            if (valid) {
              addczList(parmas)
                .then((res) => {
                  console.log(res);
                  this.$message({
                    message: "保存成功",
                    type: "success",
                  });
                  this.headCancel();
                })
                .catch((error) => {});
            }
          });
        }
      });
    },
    // 获取编码
    getczCode() {
      getczCode({ organizationId: this.userInfo.dept_id }).then((res) => {
        // this.form.recptCode = res.data.data;
        this.$set(this.form, "disposalCode", res.data.data || "");
      });
    },
    selectionRowChange(row) {
      // this.form.itemName = row.itemName;
      this.$set(this.form, "itemName", row.itemName);

      this.form.itemId = row.itemId;
      this.form.itemCode = row.itemCode;
      // this.form.batchCode = row.batchCode;
      this.$set(this.form, "batchCode", row.batchCode);
      this.form.unitOfMeasure = row.unitOfMeasure;
      this.form.warehouseId = row.warehouseId;
      this.form.spec = row.spec;
      this.form.dmItemVendor = row.dmItemVendor;
      this.listDialog = false;
    },
    showFile(link) {
      this.$refs.fileView.showFile(link)
    },
  },
};
</script>

<style scoped lang="scss">
.content {
  height: 100%;
}
.el-input-number.el-input-number--small {
  width: 100%;
}
::v-deep.listDialog {
  #gridLayout {
    height: calc(100% - 60px);
    .avue-crud {
      height: 100%;
      .el-card {
        height: 100%;
        .el-card__body {
          height: 100%;
          .el-form {
            height: 100%;
            .el-table {
              height: 100% !important;
            }
          }
        }
      }
      .avue-crud__pagination {
        height: auto;
      }
    }
  }
}
</style>
