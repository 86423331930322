var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "content" },
    [
      _c(
        "el-container",
        [
          _c(
            "el-main",
            { staticStyle: { background: "#ffffff" } },
            [
              _c("head-layout", {
                attrs: {
                  "head-title": _vm.headTiTle,
                  "head-btn-options":
                    _vm.type == "view"
                      ? _vm.headBtnViewsOptions
                      : _vm.headBtnOptions,
                },
                on: {
                  "head-save": _vm.headSave,
                  "head-add": _vm.headAdd,
                  "head-cancel": _vm.headCancel,
                },
              }),
              _c(
                "div",
                { staticStyle: { padding: "12px" } },
                [
                  _c(
                    "el-form",
                    {
                      ref: "form",
                      attrs: {
                        model: _vm.form,
                        "label-width": "120px",
                        rules: _vm.rules,
                        disabled: _vm.type == "view",
                      },
                    },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "处置申请编号",
                                    prop: "disposalCode",
                                  },
                                },
                                [
                                  _c(
                                    "el-input",
                                    {
                                      attrs: { maxlength: "64" },
                                      model: {
                                        value: _vm.form.disposalCode,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form,
                                            "disposalCode",
                                            $$v
                                          )
                                        },
                                        expression: "form.disposalCode",
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          attrs: { slot: "append" },
                                          slot: "append",
                                        },
                                        [
                                          _vm._v(
                                            "\n                    自动生成\n                    "
                                          ),
                                          _c("el-switch", {
                                            on: { change: _vm.codeAutoClick },
                                            model: {
                                              value: _vm.codeAuto,
                                              callback: function ($$v) {
                                                _vm.codeAuto = $$v
                                              },
                                              expression: "codeAuto",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "危化品名称",
                                    prop: "itemName",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      maxlength: 255,
                                      "show-word-limit": "",
                                    },
                                    on: { focus: _vm.clickListDialog },
                                    model: {
                                      value: _vm.form.itemName,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "itemName", $$v)
                                      },
                                      expression: "form.itemName",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "处置数量",
                                    prop: "quantity",
                                  },
                                },
                                [
                                  _c("el-input-number", {
                                    attrs: {
                                      min: 1,
                                      precision: 2,
                                      placeholder: "",
                                    },
                                    on: { input: _vm.handleInput },
                                    model: {
                                      value: _vm.form.quantity,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "quantity", $$v)
                                      },
                                      expression: "form.quantity",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: { label: "批次号", prop: "batchCode" },
                                },
                                [
                                  _c("el-input", {
                                    attrs: { disabled: "" },
                                    model: {
                                      value: _vm.form.batchCode,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "batchCode", $$v)
                                      },
                                      expression: "form.batchCode",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "处置时间",
                                    prop: "disposalDate",
                                  },
                                },
                                [
                                  _c("el-date-picker", {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      type: "date",
                                      placeholder: "选择日期",
                                      "default-value": new Date(),
                                      "value-format": "yyyy-MM-dd HH:mm:ss",
                                    },
                                    model: {
                                      value: _vm.form.disposalDate,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "disposalDate", $$v)
                                      },
                                      expression: "form.disposalDate",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: { label: "申请人", prop: "userName" },
                                },
                                [
                                  _c("el-input", {
                                    attrs: { placeholder: "申请人" },
                                    on: {
                                      focus: function ($event) {
                                        return _vm.handleUserModeOpen()
                                      },
                                    },
                                    model: {
                                      value: _vm.form.userName,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "userName", $$v)
                                      },
                                      expression: "form.userName",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: { label: "处置原因", prop: "remark" },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      type: "textarea",
                                      maxlength: 500,
                                      "show-word-limit": "",
                                    },
                                    model: {
                                      value: _vm.form.remark,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "remark", $$v)
                                      },
                                      expression: "form.remark",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-container",
        [
          _c(
            "el-main",
            { staticStyle: { background: "#ffffff" } },
            [
              _c("head-layout", {
                attrs: { "head-title": "供应商信息", "head-btn-options": [] },
                on: {
                  "head-save": _vm.headSave,
                  "head-add": _vm.headAdd,
                  "head-cancel": _vm.headCancel,
                },
              }),
              _c(
                "div",
                { staticStyle: { padding: "12px" } },
                [
                  _c(
                    "el-form",
                    { attrs: { model: _vm.form, "label-width": "100px" } },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "供应商名称" } },
                                [
                                  _c("div", [
                                    _vm._v(
                                      _vm._s(_vm.form.dmItemVendor.vendorName)
                                    ),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "生产许可证号" } },
                                [
                                  _c("div", [
                                    _vm._v(
                                      _vm._s(_vm.form.dmItemVendor.license)
                                    ),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "技术说明书" } },
                                [
                                  _c(
                                    "el-link",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.showFile(
                                            _vm.form.dmItemVendor.docUrl
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.form.dmItemVendor.docName)
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "产品合格证" } },
                                [
                                  _c(
                                    "el-link",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.showFile(
                                            _vm.form.dmItemVendor.qcUrl
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.form.dmItemVendor.qcName)
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-container",
        [
          _c(
            "el-main",
            { staticStyle: { background: "#ffffff" } },
            [
              _c("head-layout", {
                attrs: { "head-title": "处置单位信息", "head-btn-options": [] },
                on: {
                  "head-save": _vm.headSave,
                  "head-add": _vm.headAdd,
                  "head-cancel": _vm.headCancel,
                },
              }),
              _c(
                "div",
                { staticStyle: { padding: "12px" } },
                [
                  _c(
                    "el-form",
                    {
                      ref: "czForm",
                      attrs: {
                        model: _vm.form,
                        "label-width": "100px",
                        rules: _vm.rules,
                        disabled: _vm.type == "view",
                      },
                    },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "处置单位",
                                    prop: "vendorName",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      maxlength: 255,
                                      "show-word-limit": "",
                                    },
                                    model: {
                                      value: _vm.form.vendorName,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "vendorName", $$v)
                                      },
                                      expression: "form.vendorName",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "资质证书" } },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      maxlength: 255,
                                      "show-word-limit": "",
                                    },
                                    model: {
                                      value: _vm.form.certificate,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "certificate", $$v)
                                      },
                                      expression: "form.certificate",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "备注说明" } },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      type: "textarea",
                                      maxlength: 500,
                                      "show-word-limit": "",
                                    },
                                    model: {
                                      value: _vm.form.disposalRemark,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.form,
                                          "disposalRemark",
                                          $$v
                                        )
                                      },
                                      expression: "form.disposalRemark",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
          staticClass: "listDialog",
          attrs: {
            title: "请选择危险品",
            visible: _vm.listDialog,
            width: "80%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.listDialog = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticStyle: { width: "100%", height: "520px" } },
            [
              _c("grid-layout", {
                ref: "gridLayOut",
                attrs: {
                  "table-options": _vm.tableOption,
                  "data-total": _vm.page.total,
                  "table-data": _vm.tableData,
                  "table-loading": _vm.tableLoading,
                  page: _vm.page,
                },
                on: {
                  "gird-handle-select-click": _vm.selectionChange,
                  "page-current-change": _vm.getList,
                  "page-size-change": _vm.getList,
                },
                scopedSlots: _vm._u([
                  {
                    key: "customBtn",
                    fn: function ({ row }) {
                      return [
                        _c(
                          "el-button",
                          {
                            staticStyle: { margin: "0 3px" },
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.selectionRowChange(row)
                              },
                            },
                          },
                          [_vm._v("选择")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "avue-dialog__footer" },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.listDialog = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.handleselectionChange },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
      _c("fileView", {
        ref: "fileView",
        attrs: { title: "危化品相关文件预览" },
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "请选择处置人",
            visible: _vm.personShow,
            width: "80%",
            top: "8vh",
          },
          on: {
            "update:visible": function ($event) {
              _vm.personShow = $event
            },
          },
        },
        [
          _c("UserDetpDialog", {
            ref: "UserDetpDialog",
            on: { "select-data": _vm.selectData },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }